export enum EUserAuthority {
  ACTIVITY_PROFILE = 'ACTIVITY_PROFILE',
  ADMIN = 'ADMIN',
  ADMIN_USER_MANAGEMENT = 'ADMIN_USER_MANAGEMENT',
  AQUAFACTS = 'AQUAFACTS',
  CREATE_NEWS = 'CREATE_NEWS',
  DEVELOPER = 'DEVELOPER',
  FISHFACTS = 'FISHFACTS',
  HISTORICAL_CATCH = 'HISTORICAL_CATCH',
  HISTORICAL_TRACK = 'HISTORICAL_TRACK',
  ROLE_ANONYMOUS = 'ROLE_ANONYMOUS',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  USER = 'USER',
  VESSEL_EXTENDED_FIELDS = 'VESSEL_EXTENDED_FIELDS',
  VIEW_ANALYTICS = 'VIEW_ANALYTICS',
  VIEW_BIOLYTICS = 'VIEW_BIOLYTICS',
  VIEW_HARVESTING = 'VIEW_HARVESTING',
  VIEW_MAP_ICE_LAYER = 'VIEW_MAP_ICE_LAYER',
  VIEW_OWNER = 'VIEW_OWNER',
  VIEW_MAP = 'VIEW_MAP',
  VIEW_USER_CUSTOM_AKER_BIOMARINE = 'VIEW_USER_CUSTOM_AKER_BIOMARINE',
  VIEW_USER_CUSTOM_NORDIC_WILDFISH = 'VIEW_USER_CUSTOM_NORDIC_WILDFISH',
  VIEW_USER_CUSTOM_SCANBIO = 'VIEW_USER_CUSTOM_SCANBIO',
  VIEW_USER_CUSTOM_VROLIJK = 'VIEW_USER_CUSTOM_VROLIJK'
}

export type TUserInfo = {
  authorities: ReadonlyArray<EUserAuthority>,
  firstName: string,
  fleets: ReadonlyArray<{ id: number, name: string }>,
  groupId: number,
  groupName: string,
  lastName: string,
  id: number,
  newsId: ReadonlyArray<number>,
  serviceProvidersId: ReadonlyArray<number>,
  userName: string
}
